<template>
	<div class="grid">
		<div class="col-12">
			<div class="card border-green-500 border-left-3  mb-0
			flex justify-content-between align-items-center">
				<div class="flex align-items-center">
					<p class="mb-0 mr-2">
						<i class="pi pi-exclamation-triangle mt-1 font-bold text-green-500 mr-2 "></i>
					</p>
					<div>
						<p class="mb-1">

							正在升级您的战队

						</p>
						<p class="text-gery" style="font-size:small">
							一旦升级以后，将不可撤回
						</p>
					</div>
				</div>
				<Button label="取消升级，返回查看我的角色" icon="iconfont icon-jiaose-"
			 	class="mr-2" @click="backTeam"></Button>


			</div>
		</div>
	</div>
	

	<div class="grid mt-4" v-if="roleInfo">
		<div class="col-12 lg:col-8 ">
			<div class="card mb-0 text-center imgCard text-left noHover">
				<div class="content">

					<div class="flex justify-content-between align-items-center mb-4">
						<div class="content_title text-white">
							{{roleInfo.role.name}}
						</div>
						<div class="flex nowInfoBtn">
							<div class="nowInfo">
								等级：<span class="text-warning text-bold">{{rank}}</span>
							</div>
							<div class="nowInfo">
								<span class="text-bold">战力：{{minePower}}</span>
							</div>
							<div class="nowInfo">
								宝石维护天数：	{{(roleInfo.role.leaseExpireTime).toNumber()}}
							</div>
						</div>
					</div>
					<div class="dotted_line"></div>
					<div class="mt-4 ">
						
						<div class="mb-4 mt-4">

							<ul class="tab_ul">
								<li :class="isChose==0?'active':''" @click="isChose=0">
									武器
								</li>
								<li :class="isChose==1?'active':''" @click="isChose=1">
									宝石
								</li>
								
							</ul>
						</div>
						<div class="grid" v-if="isChose==0&&ArmList">
							<template v-for="(item,index) in ArmList" >
								<div class="col-12 lg:col-6 xl:col-4"  
									v-if="item.number.toNumber()==roleInfo.role.number.toNumber()">
									<div class="card mb-0 text-center imgCard text-left gemCard" @click="addArm(index,item)">
									
										<img :src='require("@/assets/img/arm/monkey/"+item.quality.toNumber()+".jpg")' class="cover_img" v-if="item.number.toNumber()==1"/>
										<img :src='require("@/assets/img/arm/pig/"+item.quality.toNumber()+".jpg")' class="cover_img" v-else-if="item.number.toNumber()==2"/>
										<img :src='require("@/assets/img/arm/shazeng/"+item.quality.toNumber()+".jpg")' class="cover_img" v-else-if="item.number.toNumber()==3"/>
										<div class="content ">
											<div class="flex justify-content-between">
												<h5 class="title">
													{{item.name}}
												</h5>
												
											</div>
											<p class="">
												<span>品质：</span>
												<span class="text-danger">
													{{(item.quality).toNumber()}}
												</span>
											</p>
											
										</div>
									</div>
								</div>
							</template>
							
						</div>
			
					
						<div class="grid" v-if="isChose==1">
							<div class="col-12 lg:col-6 xl:col-4" v-for="(item,index) in GemList"
							 v-if="GemList">
								<div class="card mb-0 text-center imgCard text-left gemCard" @click="addGem(index,item)"
								>
									<img :src='require("@/assets/img/gem/"+item.quality.toNumber()+".jpg")' class="cover_img" />
									<div class="content ">
										<div class="flex justify-content-between">
											<h5 class="title">
												{{item.name}}
											</h5>
											
										</div>
										<p>
											<span>品质：</span>
											<span class="text-danger">
												{{(item.quality).toNumber()}}
											</span>
										</p>
										<p>
											<span>伤害：</span>
											<span class="text-danger">
												{{(item.aggressivity).toNumber()}}
											</span>
										</p>
									
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-4">
			<div class="card mb-0 text-center imgCard text-left noHover ">
				<div class="content pt-2">

					<div class="">
						<div class="mb-4">

							<ul class="tab_ul text-center top_tab_ul">
								<li :class="isNowChose==0?'active':''" @click="isNowChose=0" class="w_10">
									武器
								</li>
								<li :class="isNowChose==1?'active':''" @click="isNowChose=1" class="w_10">
									宝石
								</li>
								
							</ul>
						</div>
						
						<div class="nowListInfo mb-4 text-center">
							<div class="nowInfo">
								
								<span class="text-warning text-bold">
									<i class="iconfont icon-jiaose- mr-2 text-white"/>
									{{Number(NowArmList.length)+Number(roleInfo.arms.length)}}/10
								</span>
							</div>
							<div class="nowInfo">
								
								<span class="text-warning text-bold">
									<i class="iconfont icon-zhubaopeishi mr-2 text-white"/>
									{{NowGemList.length}}/{{maxGem}}
								</span>
							</div>
						</div>
						
						<div v-if="isNowChose==0">
							<div class="flex list_item align-items-center justify-content-between mb-2"
								v-for="(item,index) in NowArmList" v-if="ArmList"
								:style="{backgroundImage:'url('+require('@/assets/img/listbg/arm'+item.number.toNumber()+'.png')+')'}"
								@click="removeArm(index,item)">
								<p>
									{{item.name}}
									<span class="ml-1">(品质：{{(item.quality).toNumber()}})</span>
								</p>
								<i class="iconfont icon-fanhui mr-2 text-white" />
								
							</div>
						</div>
						
						<div v-if="isNowChose==1">
							<div class="flex list_item align-items-center justify-content-between mb-2"
								v-for="(item,index) in NowGemList" v-if="NowGemList"
								:style="{backgroundImage:'url('+require('@/assets/img/listbg/gem'+item.quality.toNumber()+'.png')+')'}"
								@click="removeGem(index,item)">
								<div>
									{{item.name}}
									<span class="ml-1">(伤害：{{(item.aggressivity).toNumber()}})</span>
									<p>
										<span>品质：</span>
										<span class="text-danger">
											{{(item.quality).toNumber()}}
										</span>
									</p>
									
								</div>

								<i class="iconfont icon-fanhui mr-2 text-white" />
								
							</div>
						</div>
						<div class="" v-if="NowArmList.length>0||NowGemList.length>0">
							<hr>
							<Button label="确定升级" icon="iconfont icon-jiaose-"
							 class="mr-2" @click="upgradeArm()"
								:disabled="NowArmList.length>10-roleInfo.arms.length
								&&roleInfo.maxGems>roleInfo.gemstones.length||
								NowGemList.length>maxGem"></Button>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div>
</template>
<style scoped lang="scss">
	.imgCard{
		img{
			height: -webkit-fill-available;
		}
		.content{
			padding: 20px 10px;
			.roleName{
			
			}
		}
		.nowInfo{
				color:$text_btn_gery_color;
				padding: 8px 15px;
				border-radius: $radisIconbtn;
				background: $btn_gery_color;
				margin-left: 10px;
			}
			.nowListInfo{
				width: 100%;
				
				.nowInfo{
					display: inline-block;
					margin:0;
					margin-right: 10px;
				}
			}
		&.noHover{
			&:hover{
				border:1px solid rgba(0, 0, 0, 0.4);
			}
		}
	
		
	}
		
	.dotted_line {
		border-bottom: 2px solid #475A75;
	}

	.list_item {
		padding: 10px;
		height: 100%;
		background: rgba(0,0,0,0.7);
		top: 0;
		height: 50px;
		color: #fff;
		border-radius: $radisIconbtn;
		object-fit: cover;
		background-size: cover;
		
		i{
			text-align: right;
			display: block;
		}
		p{
			margin: 0 !important;
		}
		.cover_img {
			width: 8rem;
			height: auto;

		}
	}

	.tab_ul {
		padding: 0;
		
		li {
			display: inline;
			list-style: none;
			padding: 8px 30px;
		
			background: $btn_gery_color;
			color:$text_btn_gery_color;
			
			&:nth-child(1){
				border-top-left-radius:$radisIconbtn;
				border-bottom-left-radius:$radisIconbtn;
				margin-right: 10px;
			}
			&:last-child{
				border-top-right-radius:$radisIconbtn;
				border-bottom-right-radius:$radisIconbtn;
			}
			&.active {
				color:$text-themeBg-color;
				background:$themeColor;
			}
		}
	}
	.top_tab_ul{
		display: flex;
		li {
			&:nth-child(1){
				margin-right: 5px;
			}
		}
	}
</style>

<script>
	import {
		defineComponent,
		ref,
		watch,
		computed
	} from 'vue';
	import {
		useRouter
	} from "vue-router";
	import {
		getBlbalance
	} from "../../wallet/tokenAuto";
	import {
		getGemsList
	} from "../../wallet/LogicGem";
	import {
		getArmsList
	} from "../../wallet/LogicArms";
	import {
		getRoleArms,
		rolePushArmsGem
	} from "../../wallet/LogicRole";

	import * as getAttr from "../../hooks/getAttr";
import { useStore } from "vuex";
	export default defineComponent({
		name: "UpgradePage",
		components: {

		},
		setup() {
			const store = useStore();
			const router = useRouter();
			let roleid = ref(router.currentRoute.value.params.id);
			let roleInfo = ref();
			let maxGem=ref(0);
			let rank=ref("D");
			let minePower=ref();
			let ArmList = ref();
			let NowArmList = ref('');
			NowArmList.value = new Array();
			let GemList = ref();
			let NowGemList = ref('');
			let isChose = ref(0);
			let isNowChose=ref(0);
			NowGemList.value = new Array();
			
			const backTeam = () => {
				router.push("/role")
			}

			//排序
			


			const getArmsListEvent = () => {
				ArmList.value = '';
				getArmsList().then(res => {
					let newArr = [];

					for (let i = 0; i < res.length; i++) {

						newArr[i] = res[i]
					}
					getAttr.sortArr(newArr,0).then(res=>{
						ArmList.value = res;
					});
						
					console.log("获取武器列表", ArmList.value)

				}).catch(error => {
					console.log("调用方法失败")
				})
			}
			getArmsListEvent();
			//获取当前角色信息
			const getRoleInfo = () => {
				roleInfo.value='';
				getRoleArms(roleid.value).then(res => {
					roleInfo.value=res;
					console.log("角色信息",roleInfo.value);
					rank.value=roleInfo.value.role.rank;
					minePower.value=roleInfo.value.minePower;
					maxGem.value = roleInfo.value.maxGems.toNumber()-roleInfo.value.gemstones.length;
				})
			}
			getRoleInfo();


			const getRankFn=()=>{
				let roleArms=[];
				roleInfo.value.arms.forEach((i)=>{
					roleArms.push(i);
				})
				let nowRoleArm=NowArmList.value;
				let calArms=roleArms.concat(nowRoleArm)
				getAttr.getRank(calArms).then(res=>{
					rank.value=res;
				});
			}

			const getPowerFn=()=>{
				
				getAttr.getPower(roleInfo.value.minePower,NowGemList.value).then(res=>{
					minePower.value=res;
				});
			}
			const addArm = (index, item) => {
				
				
				if(NowArmList.value.length<10-roleInfo.value.arms.length){
					maxGem.value = Number(maxGem.value) + item.quality.toNumber();
					NowArmList.value.push(item);

					ArmList.value.splice(index, 1);
					getAttr.sortArr(NowArmList.value,0).then(res=>{
						NowArmList.value = res;
					});
					getRankFn()
				}
				
			
			}

			const removeArm = (index, item) => {

				NowArmList.value.splice(index, 1);
				ArmList.value.push(item);
				getAttr.sortArr(ArmList.value,0).then(res=>{
					ArmList.value = res;
				});
				if(NowArmList.value.length<10-roleInfo.value.arms.length){
					maxGem.value = Number(maxGem.value) - item.quality.toNumber();
				}
				
				getRankFn()
			}
			//获取宝石列表
			const getGemListEvent = () => {
				GemList.value = '';
				getGemsList().then(res => {
					let newArr = [];

					for (let i = 0; i < res.length; i++) {

						newArr[i] = res[i]
					}
					
					getAttr.sortArr(newArr,1).then(res=>{
					
						GemList.value = res;
					});
					console.log("获取宝石列表", GemList.value)
				}).catch(error => {
					console.log("调用方法失败")
				})
			}
			getGemListEvent();
			const addGem = (index, item) => {

				NowGemList.value.push(item);
				GemList.value.splice(index, 1);
				getAttr.sortArr(NowGemList.value,1).then(res=>{
				
					NowGemList.value = res;
				});
				getPowerFn();
			}

			const removeGem = (index, item) => {
				minePower.value=minePower.value-item.aggressivity.toNumber();
				NowGemList.value.splice(index, 1);
				GemList.value.push(item);
				getAttr.sortArr(GemList.value,1).then(res=>{
					GemList.value = res;
				});
				getPowerFn();
			}
			//升级角色武器
			const upgradeArm = () => {
				let Armids = [];
				for (let i = 0; i < NowArmList.value.length; i++) {
					Armids.push((NowArmList.value[i].itemId).toNumber())
				}
				let Gemids = [];
				for (let i = 0; i < NowGemList.value.length; i++) {
					Gemids.push((NowGemList.value[i].itemId).toNumber())
				}
				rolePushArmsGem(roleid.value, Armids, Gemids).then(res => {
				  store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
					getBlbalance()
					getRoleInfo()
					NowGemList.value = [];
					NowArmList.value = [];
				})
			}
			watch(() => isChose.value, () => {

			})
			return {
				backTeam,
				roleInfo,
				ArmList,
				getArmsListEvent,
				addArm,
				removeArm,
				NowArmList,
				upgradeArm,
				GemList,
				addGem,
				NowGemList,
				removeGem,
				isChose,
				maxGem,
				isNowChose,
				rank,
				minePower
			}
		}

	})
</script>